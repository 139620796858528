import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteItems, updateStateData } from "../../../Store/actions";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { checkUserDetailsFromCookie } from "../../../api_calls/userManagement";
import { Box, Stack } from "@mui/material";
import { StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { FilterAlt } from "@mui/icons-material";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";
import { fetchCompanies, filterCompanies } from "../../../api_calls/companyRequests";

const CompanyListFilter = (props) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const [companyNameFilter, setCompanyNameFilter] = React.useState([]);

  const t = useCustomTranslation();
  const dispatchAction = useDispatch();
  const checkUserDetails = checkUserDetailsFromCookie();
  
  const saveCompanyFilters = (e) => {
    e.preventDefault();
    if (companyNameFilter.length === 0) {
      clearfilters();
      // toast("Please select filters before applying.", { type: "error" });
    } else {
      const filterKeywords = ["company_name"];
      const selectedFilters = [companyNameFilter];
      let filterValues = {};
      /*
        There could be a simple straight forward way of adding values to the redux state.
        we have used map function here just to iterate through each record. Because otherwise,
        we get some error in browser console when we try to update a particular index in an array.
        However, when we use loop/map function then it doesn't show any error. 
        May be in future, we can figure out a better way to do this. But for time being, it's been
        implemented this way.
      */
      filterKeywords.map((keyword, index) => {
        filterValues = {
          ...filterValues,
          [keyword]: typeof selectedFilters[index] === "string" ? selectedFilters[index] : [...selectedFilters[index]],
        };
      });

      //save all selected filters to redux state (it will be accessed for generating powerpoint later)
      dispatchAction(
        updateStateData({
          company_list_filters: filterValues,
        })
      );
      // filter records according to the selection
      filterCompanies();
    }
  };


  const clearfilters = () => {
    // e.preventDefault();
    setCompanyNameFilter([]);

    // //reset company listing
    dispatchAction(deleteItems(["company_list_filters", "filtered_company_list", "company_list_filter_applied"]));
  };

  React.useEffect(() => {
    // loadCampaigns();
    fetchCompanies();
  }, []);

  return (
    // typeof Clips.clip_names_list !== "undefined" && (
      <>
        {/* ----------- filter row 1 -------- */}
        <Box>
          <Stack direction={"row"} spacing={2}>
            {/* ------- company name filter --------- */}
            {typeof currentStateData.company_name_list !== "undefined"
            ?<MultiSelect
              enableFullWidth={false}
              label={"Company"}
              options={
                checkUserDetails?.user_detail?.user_type === "cyreen" 
                  ? [
                      { id: 1, label: "Cyreen"},
                      ...currentStateData.company_name_list
                    ]
                  : currentStateData.company_name_list
              }
              
              selectionCheck={(option, value) => option.id === value.id}
              defaultVal={companyNameFilter}
              usedFor="filter"
              onChangeCall={(selectedOption) => {
                console.log("Selected Option: ", selectedOption)
                setCompanyNameFilter(selectedOption);
              }}
            />
            :""}
            <StyledStack direction={"row"} spacing={2}>
              <ActionButton onClick={saveCompanyFilters} label={t("apply_filter_button_text")} icon={<FilterAlt />} />
            </StyledStack>
          </Stack>
        </Box>
      </>
    //)
  );
};

export default CompanyListFilter;
