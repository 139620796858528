import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import RetailAnalyticsFilter from "./Filters/RetailAnalyticsFilter";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import { resetBrandReportView, storeListOptionsForTableauFilter, findStoreIds } from "../../api_calls/utils";
import { useSelector } from "react-redux";

const InstoreDuration = () => {
  const userStateDate = useSelector((state) => state.userSelections);
  const [userInfo, setUserInfo] = React.useState({});
  React.useEffect(() => {
    const checkUserDetails = checkUserDetailsFromCookie();
    let userDetailObj = {};
    if (checkUserDetails.success === true) {
      userDetailObj = checkUserDetails.user_detail;
    }
    setUserInfo(userDetailObj);
    resetBrandReportView();
  }, []);
  return (
    <StyledMainBox component="main">
      {/* ------------- filters -------------- */}
      <RetailAnalyticsFilter tabVizId={"inStoreDurationViz"} />
      <Box height={1} />
      {/* ----------- tableau dashboard ---------- */}
      <Grid container spacing={2}>
        {typeof userInfo.root_company_id !== "undefined" &&
          typeof userStateDate.stores_list !==
            "undefined"(
              <TableauDash
                vizId="inStoreDurationViz"
                vizUrl={
                  "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
                  userInfo.root_company_id +
                  "_master_" +
                  process.env.REACT_APP_ENV_NAME +
                  "/Duration"
                }
                applyFilter={true}
                filterConfig={{
                  store: {
                    name: "Id Store",
                    values: findStoreIds(storeListOptionsForTableauFilter("retail")),
                  },
                }}
              />
            )}
      </Grid>
    </StyledMainBox>
  );
};

export default AccessControl(InstoreDuration, "retail_analytics");
