import React from "react";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import { StyledTabs } from "../../../components/commonComponents/StyledComponents/styled";

import { useNavigate, useParams } from "react-router-dom";
import AccessControl from "../../../components/commonComponents/Hoc/AccessControl";
import { fetchCampaign } from "../../../api_calls/campaignRequests";
import { campaign_analytics_tabs_config } from "../dashboardsConfig";
import { fetchBrandFilters } from "../../../api_calls/brandReport";
import { generateRandomNumber } from "../../../api_calls/utils";
import ImpressionsFilter from "../filters/ImpressionsFilter";
import { useSelector } from "react-redux";
import { BarChart, Visibility } from "@mui/icons-material";
import TableauDash from "../../../components/commonComponents/TableauDashboard/TableauDash";
import TotalPerformanceFilters from "../filters/TotalPerformanceFilters";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function dashboardMarkup(vizId, vizUrl, applyFilter, filterConfig) {
  return (
    <Grid container spacing={2}>
      <TableauDash vizId={vizId} vizUrl={vizUrl} applyFilter={applyFilter} filterConfig={filterConfig} />
    </Grid>
  );
}

const TestAnalytics = ({ viewMode }) => {
  const [value, setValue] = React.useState(0);
  const [reportTabs, setReportTabs] = React.useState([]);
  const currentStateData = useSelector((state) => state.userSelections);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const filterKeyword = viewMode === "Brand" ? "brand_filters" : "retailer_filters";
  let tabs = [
    {
      label: "Impressions",
      icon: <Visibility />,
      unique_key: "impressions_1",
      dashboard: dashboardMarkup(
        "impressionsDash",
        "https://dub01.online.tableau.com/t/tableaucyreende/views/3_unimarc_impressions_master_test/Impressions",
        true,
        {
          store: {
            name: "name_store",
            values: currentStateData[filterKeyword]?.impression_filter?.stores,
          },
          id_campaign: {
            name: "id_campaign",
            values: [id],
          },
        }
      ),
      filter_component: <ImpressionsFilter vizId="impressionsDash" />,
    },
    {
      label: "Performance",
      icon: <BarChart />,
      unique_key: "performance_1",
      dashboard: dashboardMarkup(
        "performanceDash",
        "https://dub01.online.tableau.com/t/tableaucyreende/views/3_unimarc_performance_master_test/SinglePerformance",
        true,
        {
          store: {
            name: "name_store",
            values: currentStateData[filterKeyword]?.performance_filter?.stores,
          },
          analysis_group: {
            name: "name_adlevel",
            values: currentStateData[filterKeyword]?.performance_filter?.analysis_group,
          },
          week: {
            name: "week",
            values: currentStateData[filterKeyword]?.performance_filter?.weeks,
          },
          id_campaign: {
            name: "id_campaign",
            values: [id],
          },
        }
      ),
      filter_component: <TotalPerformanceFilters vizId="performanceDash" />,
    },
  ];

  React.useEffect(() => {
    // const reportTabsConfig = campaign_analytics_tabs_config(viewMode);
    setReportTabs(tabs);
  }, []);

  return (
    <>
      {reportTabs.length !== 0 && (
        <>
          <Box
            sx={{
              display: "flex", // Make the Box a flex container
              borderBottom: 1,
              borderColor: "divider",
              position: "sticky",
              top: "0",
              zIndex: 1,
              backgroundColor: "#fff",
            }}
          >
            <Box component={"div"}>
              <StyledTabs value={value} onChange={handleChange} aria-label="brand analytics" textColor={"#F04B55"}>
                {reportTabs.map((tabDetail) => {
                  return <Tab key={tabDetail.unique_key} icon={tabDetail.icon} label={tabDetail.label} {...a11yProps(0)} />;
                })}
              </StyledTabs>
            </Box>
            {/* ----------- filters section ------------ */}
            <Box
              component={"div"}
              sx={{
                flex: 1,
              }}
            >
              {reportTabs[value].filter_component}
            </Box>
          </Box>

          {/* ============== tab contents =========== */}
          {reportTabs.length !== 0 &&
            reportTabs.map((tabDetail, index) => {
              return (
                <CustomTabPanel value={value} index={index}>
                  {tabDetail.dashboard}
                </CustomTabPanel>
              );
            })}
        </>
      )}
    </>
  );
};

export default TestAnalytics;
