import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteItems, updateStateData } from "../../../Store/actions";
import { loadCampaigns } from "../../../api_calls/campaignRequests";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { Box, Stack } from "@mui/material";
import { StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { FilterAlt } from "@mui/icons-material";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";
import { filterClips } from "../../../api_calls/clipRequests";

const ClipListFilters = (props) => {
  const Clips = useSelector((state) => state.userSelections);
  const [campaignNameFilter, setCampaignNameFilter] = React.useState([]);
  const [clipNameFilter, setClipNameFilter] = React.useState([]);
  const [clipCompanyNameFilter, setClipCompanyNameFilter] = React.useState([]);

  const [statusFilter, setStatusFilter] = React.useState([]);

  const t = useCustomTranslation();
  const durationFilterOptions = [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 2,
      label: "Scheduled",
    },
    {
      value: 3,
      label: "Completed",
    },
  ];

  const dispatchAction = useDispatch();

  const saveClipFilters = (e) => {
    e.preventDefault();
    if (
      campaignNameFilter.length === 0 &&
      clipNameFilter.length === 0 &&
      statusFilter.length === 0 &&
      clipCompanyNameFilter.length === 0
    ) {
      clearfilters();
      // toast("Please select filters before applying.", { type: "error" });
    } else {
      const filterKeywords = ["campaign_name", "clip_name", "clip_company_name", "status_filter"];
      const selectedFilters = [campaignNameFilter, clipNameFilter, clipCompanyNameFilter, statusFilter];
      let filterValues = {};
      /*
        There could be a simple straight forward way of adding values to the redux state.
        we have used map function here just to iterate through each record. Because otherwise,
        we get some error in browser console when we try to update a particular index in an array.
        However, when we use loop/map function then it doesn't show any error. 
        May be in future, we can figure out a better way to do this. But for time being, it's been
        implemented this way.
      */
      filterKeywords.map((keyword, index) => {
        filterValues = {
          ...filterValues,
          [keyword]: typeof selectedFilters[index] === "string" ? selectedFilters[index] : [...selectedFilters[index]],
        };
      });

      //save all selected filters to redux state (it will be accessed for generating powerpoint later)
      dispatchAction(
        updateStateData({
          clip_list_filters: filterValues,
        })
      );
      // filter records according to the selection
      filterClips();
    }
  };

  const clearfilters = () => {
    // e.preventDefault();
    setCampaignNameFilter([]);
    setClipNameFilter([]);
    setStatusFilter([]);

    //reset clips listing
    dispatchAction(deleteItems(["clip_list_filters", "filtered_clips_list", "clips_list_filter_applied"]));
  };

  React.useEffect(() => {
    // loadCampaigns();
  }, []);

  return (
    typeof Clips.clip_names_list !== "undefined" && (
      <>
        {/* ----------- filter row 1 -------- */}
        <Box>
          <Stack direction={"row"} spacing={2}>
            {/* ------- clip name filters --------- */}
            <MultiSelect
              enableFullWidth={false}
              label={"Clip Name"}
              options={Clips.clip_names_list}
              selectionCheck={(option, value) => option.id === value.id}
              defaultVal={clipNameFilter}
              usedFor="filter"
              onChangeCall={(selectedOption) => {
                setClipNameFilter(selectedOption);
              }}
            />
            {/* ------- clip company name filters --------- */}
            <MultiSelect
              enableFullWidth={false}
              label={"Owner"}
              options={Clips.clip_company_name_list}
              selectionCheck={(option, value) => option.id === value.id}
              defaultVal={clipCompanyNameFilter}
              usedFor="filter"
              onChangeCall={(selectedOption) => {
                setClipCompanyNameFilter(selectedOption);
              }}
            />
            {/* ------- campaign name filters --------- */}
            {typeof Clips.campaign_list !== "undefined" && (
              <MultiSelect
                enableFullWidth={false}
                elemId={"campaignNameFilterForClips"}
                label={"Campaign Name"}
                options={Clips.campaign_list.campaign_name_obj}
                selectionCheck={(option, value) => option.id === value.id}
                defaultVal={campaignNameFilter}
                usedFor="filter"
                onChangeCall={(selectedOption) => {
                  setCampaignNameFilter(selectedOption);
                }}
              />
            )}
            {/* ------------ clip duration status filter ------------- */}
            <MultiSelect
              label={"Clip Progress"}
              enableFullWidth={false}
              options={durationFilterOptions}
              selectionCheck={(option, value) => option.label === value.label}
              defaultVal={statusFilter}
              usedFor="filter"
              onChangeCall={(selectedOption) => {
                setStatusFilter(selectedOption);
              }}
            />

            <StyledStack direction={"row"} spacing={2}>
              <ActionButton onClick={saveClipFilters} label={t("apply_filter_button_text")} icon={<FilterAlt />} />
              {/* <LightGreyButton onClick={clearfilters} label={t("clear_filter_button_text")} icon={<Clear />} /> */}
            </StyledStack>
          </Stack>
          <Box height={20} />
        </Box>
      </>
    )
  );
};

export default ClipListFilters;
