import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";
import { Close } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import { fetchPictureUrl } from "../../../api_calls/pictureRequests";
import { toast } from "react-toastify";

const ShowPicture = ({ open, onClose, size, closeModal, picInfo }) => {
  const [picUrl, setPicUrl] = React.useState("");
  React.useEffect(() => {
    fetchPictureUrl(picInfo.name, picInfo.picture_company_id).then((info) => {
      if (info.success === true) {
        setPicUrl(info.pic_file_url);
      } else {
        toast("Sorry, picture can't be loaded.", { type: "error" });
        closeModal();
      }
    });
  }, []);
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> {picInfo.name} </DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box sx={{ display: "flex" }}>
            {picUrl !== "" && (
              <>
                {picInfo.name.endsWith(".mp4") ? (
                  <video style={{ width: "100%", height: "auto" }} controls>
                    <source src={picUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img style={{ width: "100%", height: "auto" }} src={picUrl} />
                )}
              </>
            )}
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            label="Cancel"
            icon={<Close />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ShowPicture;
