import * as React from "react";
import { AccountCircle, Logout, MoreVert, Workspaces } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Box, Paper, Typography, Popover, Button } from "@mui/material";
import { logout } from "../../../api_calls/requestConfig";
import ResetPassword from "./Modal/ResetPassword";
import WorkSpace from "../../../pages/Login/modalContent/WorkSpace";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../../api_calls/userManagement";
import {
  HtmlTooltipLightRed,
  StyledInformationSpan,
  StyledLightRedInformationTypography,
} from "../StyledComponents/styled";

export default function BottomNav({ open }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isWorkSpaceModalOpen, setWorkSpaceModal] = React.useState(false);
  const [showWorkSpaceIcon, setShowWorkSpaceIcon] = React.useState(false);
  const [workSpaceName, setWorkSpaceName] = React.useState("");
  const [userType, setUserType] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const checkWorkSpaces = checkWorkSpaceFromCookies();
    const checkUserDetails = checkUserDetailsFromCookie();
    console.log(checkUserDetails);
    if (checkWorkSpaces.success === true) {
      if (checkWorkSpaces.workspace_options.length > 1) {
        setShowWorkSpaceIcon(true);
      }
      setWorkSpaceName(checkWorkSpaces?.current_workspace?.name);
    }
    if (checkUserDetails.success === true) {
      setUserType(checkUserDetails.user_detail);
    }
  }, []);

  return (
    <>
      {/* ----------- bottom navigation ------------ */}
      <Paper sx={{ position: "fixed", width: open ? 260 : 63, bottom: 0, left: 0, right: 0 }} elevation={2}>
        {open && workSpaceName !== "" && (
          <StyledLightRedInformationTypography
            variant="body2"
            style={{ textAlign: "center", justifyContent: "center", marginLeft: "0px" }}
          >
            <StyledInformationSpan style={{ fontSize: "15px", overflowWrap: "anywhere" }}>
              {typeof userType.user_type !== "undefined" && userType.user_type === "cyreen"
                ? workSpaceName + " - Cyreen Access"
                : workSpaceName}
              {/* {workSpaceName} */}
            </StyledInformationSpan>
          </StyledLightRedInformationTypography>
        )}
        <Box height={6} />

        {open ? (
          <BottomNavigation sx={{ backgroundColor: "rgba(240, 75, 85, 0.09)" }}>
            {/* -------- logout ------- */}
            <HtmlTooltipLightRed title="Logout">
              <BottomNavigationAction
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
                sx={{ color: "rgba(240, 75, 85, 0.89)" }}
                label="User"
                icon={<Logout />}
              />
            </HtmlTooltipLightRed>
            {/* ------- account settings --------- */}
            <HtmlTooltipLightRed title="Account Details">
              <BottomNavigationAction
                onClick={(e) => {
                  e.preventDefault();
                  openModal(true);
                }}
                sx={{ color: "rgba(240, 75, 85, 0.89)" }}
                label="User"
                icon={<AccountCircle />}
              />
            </HtmlTooltipLightRed>

            {/* ------- work space change option ----------- */}
            {showWorkSpaceIcon && (
              <HtmlTooltipLightRed title="Switch Workspace">
                <BottomNavigationAction
                  onClick={(e) => {
                    e.preventDefault();
                    setWorkSpaceModal(true);
                  }}
                  sx={{ color: "rgba(240, 75, 85, 0.89)" }}
                  label="WorkSpace"
                  icon={<Workspaces />}
                />
              </HtmlTooltipLightRed>
            )}
          </BottomNavigation>
        ) : (
          <BottomNavigation sx={{ backgroundColor: "rgba(240, 75, 85, 0.09)" }}>
            <BottomNavigationAction
              aria-describedby={id}
              onClick={handleClick}
              sx={{ color: "rgba(240, 75, 85, 0.89)" }}
              label="Popover"
              icon={<MoreVert />}
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              sx={{ marginTop: "0.8em" }}
            >
              {/* -------- POPOVER CONTENT ------- */}
              {/* -------- logout ------- */}
              <HtmlTooltipLightRed title="Logout">
                <BottomNavigationAction
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                  sx={{ color: "rgba(240, 75, 85, 0.89)" }}
                  label="User"
                  icon={<Logout />}
                />
              </HtmlTooltipLightRed>
              {/* ------- account settings --------- */}
              <HtmlTooltipLightRed title="Account Details">
                <BottomNavigationAction
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(true);
                  }}
                  sx={{ color: "rgba(240, 75, 85, 0.89)" }}
                  label="User"
                  icon={<AccountCircle />}
                />
              </HtmlTooltipLightRed>
              {/* ------- work space change option ----------- */}
              {showWorkSpaceIcon && (
                <HtmlTooltipLightRed title="Switch Workspace">
                  <BottomNavigationAction
                    onClick={(e) => {
                      e.preventDefault();
                      setWorkSpaceModal(true);
                    }}
                    sx={{ color: "rgba(240, 75, 85, 0.89)" }}
                    label="WorkSpace"
                    icon={<Workspaces />}
                  />
                </HtmlTooltipLightRed>
              )}
            </Popover>
          </BottomNavigation>
        )}
      </Paper>
      {/* ============ modal popup for reset password ========== */}
      {isModalOpen && <ResetPassword open={isModalOpen} onClose={closeModal} size={"xs"} />}
      {/* -------- change workspace modal -------- */}
      {isWorkSpaceModalOpen === true && (
        <WorkSpace
          open={isWorkSpaceModalOpen}
          onClose={() => {
            setWorkSpaceModal(false);
          }}
          size={"xs"}
          usedFor={"update"}
        />
      )}
    </>
  );
}
