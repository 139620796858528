import { styled } from "@mui/material/styles";

export const StyledList = styled("li")(({ theme }) => ({
    // "&[aria-disabled='true']": This targets the styled <li> element 
    // when it has the attribute aria-disabled set to 'true'. 
    // This is commonly used for accessibility to indicate that an element is disabled.
    "&[aria-disabled='true']": {
      pointerEvents: "inherit!important",
      backgroundColor: "#FFF!important",
      "&:hover, &:focus": {
        backgroundColor: "#FFF!important",
      },
      "&:active": {
        backgroundColor: "#FFF!important",
        pointerEvents: "none!important",
      }
    }
  }));