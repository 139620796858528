import React from "react";
import { StickyTopRowStack } from "../../../../components/commonComponents/StyledComponents/styled";
import { Box } from "@mui/material";
import StoreFilter from "../../../../components/commonComponents/Filters/StoreFilter";
import SingleSelectStoreFilter from "../../../../components/commonComponents/Filters/SingleSelectStoreFilter";

const StoreMonitoringFilter = ({ vizId }) => {
  return (
    <StickyTopRowStack direction={"row"} spacing={4}>
      {/* ------- stores filter ----------- */}
      <Box sx={{ width: 220 }}>
        <SingleSelectStoreFilter fieldName={"Id Store"} usedFor={"monitoring"} vizId={vizId} />
      </Box>
    </StickyTopRowStack>
  );
};

export default StoreMonitoringFilter;
