import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";
import { Close } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import { fetchClipUrl } from "../../../api_calls/clipRequests";
import { toast } from "react-toastify";

const PlayClip = ({ open, onClose, size, closeModal, clipInfo }) => {
  const [clipUrl, setClipUrl] = React.useState("");
  React.useEffect(() => {
    fetchClipUrl(clipInfo.name, clipInfo.clip_company_id).then((info) => {
      if (info.success === true) {
        setClipUrl(info.clip_file_url);
      } else {
        toast("Sorry, clip can't be played.", { type: "error" });
        closeModal();
      }
    });
  }, []);
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> {clipInfo.name} </DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box sx={{ display: "flex" }}>
            <StyledMainBox component="main">
              {clipUrl !== "" && (
                <video style={{ width: "100%", height: "auto" }} controls>
                  <source src={clipUrl} type="video/mp4" />
                </video>
              )}
            </StyledMainBox>
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            label="Cancel"
            icon={<Close />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default PlayClip;
