import React from "react";
import { Box } from "@mui/material";
import { filterRangeValue } from "../../../api_calls/TableauVisuals";
import { StickyTopRowStack } from "../../../components/commonComponents/StyledComponents/styled";
import StoreFilter from "../../../components/commonComponents/Filters/StoreFilter";
import DateRangeFilter from "../../../components/commonComponents/Filters/DateRangeFilter";
import SingleSelectStoreFilter from "../../../components/commonComponents/Filters/SingleSelectStoreFilter";
import { useSelector } from "react-redux";
import {
  dbFormatDate,
  formatToStandardDate,
  resetBrandReportView,
  standardFormatToday,
  storeListOptionsForTableauFilter,
  yesterdayDate,
} from "../../../api_calls/utils";

const RetailAnalyticsFilter = ({ tabVizId, singleStore }) => {
  const userStateDate = useSelector((state) => state.userSelections);
  const startDate =
    typeof userStateDate.date_range_start_date !== "undefined"
      ? new Date(userStateDate.date_range_start_date)
      : new Date("2023-02-01");

  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  return (
    <StickyTopRowStack direction={"row"} spacing={4}>
      {/* ------- stores filter ----------- */}
      <Box sx={{ width: 250 }}>
        {/* <StoreFilter fieldName={"Name Store"} usedFor={"retail"} vizId={tabVizId} /> */}
        {typeof singleStore !== "undefined" && singleStore === true ? (
          <SingleSelectStoreFilter fieldName={"Id Store"} usedFor={"retail"} vizId={tabVizId} />
        ) : (
          <StoreFilter fieldName={"Id Store"} usedFor={"retail"} vizId={tabVizId} />
        )}
      </Box>
      {/* ------------ day timestamp filter ------------ */}
      {typeof userStateDate.date_range_start_date !== "undefined" && (
        <Box sx={{ width: 250 }}>
          <DateRangeFilter
            fieldName={"DAY(Timestamp)"}
            minSelectableDate={startDate}
            maxSelectableDate={dbFormatDate(yesterday)}
            onValueChange={filterRangeValue}
            vizId={tabVizId}
          />
        </Box>
      )}
    </StickyTopRowStack>
  );
};

export default RetailAnalyticsFilter;
