import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import RetailAnalyticsFilter from "./Filters/RetailAnalyticsFilter";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import { resetBrandReportView, storeListOptionsForTableauFilter, findStoreIds } from "../../api_calls/utils";
import { useSelector } from "react-redux";

const Profitability = () => {
  const userStateDate = useSelector((state) => state.userSelections);
  const [userInfo, setUserInfo] = React.useState({});
  React.useEffect(() => {
    const checkUserDetails = checkUserDetailsFromCookie();
    let userDetailObj = {};
    if (checkUserDetails.success === true) {
      userDetailObj = checkUserDetails.user_detail;
    }
    setUserInfo(userDetailObj);
    resetBrandReportView();
  }, []);
  return (
    <StyledMainBox component="main">
      {/* ------------- filters -------------- */}
      <RetailAnalyticsFilter tabVizId={"profitabilityViz"} singleStore={true} />
      <Box height={1} />
      {/* ----------- tableau dashboard ---------- */}
      <Grid container spacing={2}>
        {typeof userInfo.root_company_id !== "undefined" && typeof userStateDate.stores_list !== "undefined" && (
          <TableauDash
            vizId="profitabilityViz"
            // vizUrl={
            //   "https://dub01.online.tableau.com/t/tableaucyreende/views/" + workSpaceId + "_Retail_Analytics/Profitability"
            // }
            vizUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
              userInfo.root_company_id +
              "_master_" +
              process.env.REACT_APP_ENV_NAME +
              "/Profitability"
            }
            applyFilter={true}
            filterConfig={{
              // store: {
              //   name: "Name Store",
              //   values: storeListOptionsForTableauFilter("retail", true),
              // },
              "Id Store": {
                name: "Id Store",
                // values: storeListOptionsForTableauFilter("retail", true),
                values:  findStoreIds([storeListOptionsForTableauFilter("retail", true)])[0],
              },
            }}
          />
        )}
      </Grid>
    </StyledMainBox>
  );
};
// remove array if all user types are allowed. if only some are allowed, add them like below
export default AccessControl(Profitability, "retail_analytics", ["cyreen", "branch", "store"]);
