import * as React from "react";
import { DisabledButtonStyle, DisabledLightGreyButtonStyle } from "./Styles";
import { HtmlTooltip } from "../StyledComponents/styled";

export default function DisabledButton({ label, onClick, icon, toolTipContent, ariaText }) {
  return (
    <HtmlTooltip title={toolTipContent}>
      <DisabledButtonStyle
        component={"a"}
        aria-label={typeof ariaText !== "undefined" ? ariaText : label}
        variant="outlined"
        onClick={onClick}
        startIcon={icon}
      >
        {label}
      </DisabledButtonStyle>
    </HtmlTooltip>
  );
}

export const DisabledGreyButton = ({ label, onClick, icon, toolTipContent, ariaText }) => {
  return (
    <HtmlTooltip title={toolTipContent}>
      <DisabledLightGreyButtonStyle
        component={"button"}
        aria-label={typeof ariaText !== "undefined" ? ariaText : label}
        variant="contained"
        onClick={onClick}
        startIcon={icon}
      >
        {label}
      </DisabledLightGreyButtonStyle>
    </HtmlTooltip>
  );
};
