import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Grid,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import {
  StyledBlackInformationTypography,
  StyledDarkRedInformationTypography,
  StyledMainBox,
} from "../../../components/commonComponents/StyledComponents/styled";
import { Close } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";

const StoreInfo = ({ open, usedFor, size, closeModal, storeInfo }) => {
  // Set a threshold for when to display the two-column layout
  const threshold = 5;
  const storeLabels = storeInfo.selected_store_options
    .sort((next, prev) => {
      return next.label.localeCompare(prev.label);
    })
    .map((store) => store.label);

  // Function to render stores in one or two columns based on the threshold
  const renderStoreList = () => {
    if (storeLabels.length === 0) {
      return <Typography>Stores</Typography>;
    }

    // Split store names into two columns if they exceed the threshold
    const middleIndex = Math.ceil(storeLabels.length / 2);
    const firstColumn = storeLabels.slice(0, middleIndex);
    const secondColumn = storeLabels.slice(middleIndex);

    return (
      <Grid container spacing={2}>
        {storeLabels.length > threshold ? (
          <>
            <Grid item xs={6}>
              <List>
                {firstColumn.map((label, index) => (
                  <ListItem key={index} disablePadding>
                    <Typography variant="body2">• {label}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={6}>
              <List>
                {secondColumn.map((label, index) => (
                  <ListItem key={index} disablePadding>
                    <Typography variant="body2">• {label}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <List>
              {storeLabels.map((label, index) => (
                <ListItem key={index} disablePadding>
                  <Typography variant="body2">• {label}</Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle>{usedFor === "clips" ? "Clip Stores" : "Picture Stores"}</DialogTitle>
      <DialogContent>
        <Box>
          <Stack direction={"row"} spacing={1}>
            <StyledDarkRedInformationTypography>
              {usedFor === "clips" ? "Clip" : "Picture"} :
            </StyledDarkRedInformationTypography>
            <StyledBlackInformationTypography>{storeInfo.name}</StyledBlackInformationTypography>
          </Stack>
        </Box>
        <Box height={20} />
        <Box>
          <Stack direction={"row"} spacing={1}>
            <StyledDarkRedInformationTypography>Total number of stores:</StyledDarkRedInformationTypography>
            <StyledBlackInformationTypography>{storeInfo.stores}</StyledBlackInformationTypography>
          </Stack>
        </Box>
        <Box height={20} />
        <Box>
          <Stack direction={"row"} spacing={1}>
            <StyledBlackInformationTypography>List of your stores:</StyledBlackInformationTypography>
          </Stack>
        </Box>

        <StyledMainBox component="main">
          <Box sx={{ display: "flex" }}>{renderStoreList()}</Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            label="Close"
            icon={<Close />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default StoreInfo;
