import * as React from "react";
import { CustomTextInput } from "./style";
import { HtmlTooltip, IconContainer } from "../StyledComponents/styled";
import { Error } from "@mui/icons-material";

export default function TextInputField({
  showlabel,
  label,
  elemId,
  classDiv,
  fieldName,
  placeholder,
  defaultVal,
  fieldRequired,
  onChangeCall,
  fieldError,
  inputType,
  iconProps,
  readOnlyFlag,
  errorStyle,
  ariaLabelText,
}) {
  return (
    <>
      <CustomTextInput
        margin="normal"
        disabled={typeof readOnlyFlag !== "undefined" ? readOnlyFlag : false}
        required={fieldRequired}
        fullWidth
        id={elemId}
        label={label}
        aria-label={typeof ariaLabelText !== "undefined" ? ariaLabelText : ""}
        name={fieldName}
        placeholder=""
        variant="standard"
        autoFocus={false}
        autoComplete="off"
        value={defaultVal}
        onChange={onChangeCall}
        type={inputType}
        error={typeof errorStyle === "undefined" && fieldError !== "" ? true : false}
        helperText={typeof errorStyle === "undefined" && fieldError !== "" ? fieldError : ""}
        InputProps={iconProps}
      />
      {errorStyle === "tooltip" && typeof fieldError !== "undefined" && fieldError !== "" && (
        <div style={{ marginTop: "30px" }}>
          <HtmlTooltip title={fieldError}>
            <IconContainer>
              <Error sx={{ color: "#AF3241" }} />
            </IconContainer>
          </HtmlTooltip>
        </div>
      )}
    </>
  );
}
