import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import { StickyTopRowStack, StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import StoreFilter from "../../components/commonComponents/Filters/StoreFilter";
import LabelsFilter from "./Filters/LabelsFilter";
import DateRangeFilter from "../../components/commonComponents/Filters/DateRangeFilter";
import { filterRangeValue } from "../../api_calls/TableauVisuals";
import SingleSelectStoreFilter from "../../components/commonComponents/Filters/SingleSelectStoreFilter";
import { useSelector } from "react-redux";
import {
  dbFormatDate,
  formatToStandardDate,
  resetBrandReportView,
  standardFormatToday,
  storeListOptionsForTableauFilter,
  findStoreIds,
} from "../../api_calls/utils";

const TrolleyUsage = () => {
  const userStateDate = useSelector((state) => state.userSelections);
  const [userInfo, setUserInfo] = React.useState({});
  const startDate =
    typeof userStateDate.date_range_start_date !== "undefined"
      ? new Date(userStateDate.date_range_start_date)
      : new Date("2023-02-01");

  // console.log(workSpaceId);

  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  React.useEffect(() => {
    const checkUserDetails = checkUserDetailsFromCookie();
    let userDetailObj = {};
    if (checkUserDetails.success === true) {
      userDetailObj = checkUserDetails.user_detail;
    }
    setUserInfo(userDetailObj);
    resetBrandReportView();
  }, []);
  return (
    <StyledMainBox component="main">
      {/* ------------- filters -------------- */}
      <StickyTopRowStack spacing={4} direction={"row"}>
        {/* ----------- labels filter ---------- */}
        <Box sx={{ width: 90 }}>
          <LabelsFilter vizId={"trolleyUsageViz"} />
        </Box>
        {/* ------- stores filter ----------- */}
        <Box sx={{ width: 250 }}>
          <SingleSelectStoreFilter fieldName={"Id Store"} usedFor={"retail"} vizId={"trolleyUsageViz"} />
        </Box>
        {/* ------------ day timestamp filter ------------ */}
        {typeof userStateDate.date_range_start_date !== "undefined" && (
          <Box sx={{ width: 250 }}>
            <DateRangeFilter
              fieldName={"DAY(Timestamp)"}
              minSelectableDate={startDate}
              maxSelectableDate={dbFormatDate(yesterday)}
              onValueChange={filterRangeValue}
              vizId={"trolleyUsageViz"}
            />
          </Box>
        )}
      </StickyTopRowStack>
      <Box height={1} />
      <Grid container spacing={2}>
        {typeof userInfo.root_company_id !== "undefined" && typeof userStateDate.stores_list !== "undefined" && (
          <TableauDash
            vizId="trolleyUsageViz"
            vizUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
              userInfo.root_company_id +
              "_master_" +
              process.env.REACT_APP_ENV_NAME +
              "/TrolleyUsage"
            }
            // vizUrl={
            //   "https://dub01.online.tableau.com/t/tableaucyreende/views/" + workSpaceId + "_Retail_Analytics/TrolleyUsage"
            // }
            applyFilter={true}
            filterConfig={{
              // store: {
              //   name: "Name Store",
              //   values: storeListOptionsForTableauFilter("retail", true),
              // },
              "Id Store": {
                name: "Id Store",
                // values:  findStoreIds([storeListOptionsForTableauFilter("retail", true)])[0],
                values: findStoreIds([storeListOptionsForTableauFilter("retail", true)])[0],
              },
            }}
          />
        )}
      </Grid>
    </StyledMainBox>
  );
};
// remove array if all user types are allowed. if only some are allowed, add them like below
export default AccessControl(TrolleyUsage, "retail_analytics", ["cyreen", "branch", "store"]);
