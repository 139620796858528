import React from "react";
import { Box } from "@mui/material";
import TableauDash from "../components/commonComponents/TableauDashboard/TableauDash";
import { useParams } from "react-router-dom";

const TestNew = () => {
  // const { id } = useParams();
  return (
    <Box component="div" sx={{ display: "flex" }}>
      <TableauDash
        vizId="generalKpiViz"
        vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/3_unimarc_impressions_master_draft/Impressions"}
        applyFilter={false}
        filterConfig={
          {
            // id_campaign: {
            //   name: "id_campaign",
            //   values: [id],
            // },
          }
        }
      />
    </Box>
  );
};

export default TestNew;
