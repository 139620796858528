import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { Add, DataArray, Save } from "@mui/icons-material";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AddNewCampaign from "./ModalContent/AddCampaign";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { pageAccessAllowed } from "../../api_calls/userManagement";
import CampaignList from "./listings/CampaignList";
import CampaignListFilters from "./filters/CampaignListFilters";
import { resetCampaignSheetData } from "../../api_calls/campaignSheet";
import { clearCampaignDetails, resetBrandReportView } from "../../api_calls/utils";

const Campaigns = () => {
  const [editPermission, setEditPermission] = useState(false);
  const [newCampaignModal, setNewCampaignModal] = useState(false);

  React.useEffect(() => {
    // check edit permission for the campaigns
    const checkEditAccess = pageAccessAllowed("campaigns", "can_edit", []);
    setEditPermission(checkEditAccess.success);
    resetBrandReportView();
    clearCampaignDetails();
  }, []);

  return (
    <>
      {/* ========== main component body =========== */}
      <StyledMainBox component="main">
        {/* <Box height={30} /> */}
        <h1>Campaigns</h1>
        {/* ============ Action button =========== */}
        {editPermission && (
          <Stack direction="row" spacing={2}>
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                setNewCampaignModal(true);
              }}
              label={"New Campaign"}
              icon={<Add />}
            />
            {/* <ActionButton
              onClick={(e) => {
                e.preventDefault();
                // openModal("choosedata");
              }}
              label={"Choose Data"}
              icon={<DataArray />}
            /> */}
          </Stack>
        )}

        {/* ============== campaign listing ============ */}
        <Box height={30} />
        {/* -------- filters ------- */}
        <CampaignListFilters />
        <Box height={30} />
        <CampaignList />
      </StyledMainBox>
      {/* ----- new campaign modal ----- */}
      {newCampaignModal && (
        <AddNewCampaign
          open={newCampaignModal}
          onClose={() => {
            setNewCampaignModal(false);
            resetCampaignSheetData();
          }}
          size={"md"}
          closeModal={() => {
            setNewCampaignModal(false);
            resetCampaignSheetData();
          }}
          usedFor={"add"}
        />
      )}
    </>
  );
};

export default AccessControl(Campaigns, "campaigns");
