import React from "react";
import { StyledMainBox, StyledRightAlignedStack } from "../../components/commonComponents/StyledComponents/styled";
import { useNavigate, useParams } from "react-router-dom";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { fetchCampaign } from "../../api_calls/campaignRequests";
import ActionButton, { LightGreyButton } from "../../components/commonComponents/Buttons/ActionButton";
import BrandReportFilter from "./ModalContent/BrandReportFilter";
import { useDispatch, useSelector } from "react-redux";
import { checkUserRole, pageAccessAllowed } from "../../api_calls/userManagement";
import AnalyticsView from "./ModalContent/AnalyticsView";
import { updateStateData } from "../../Store/actions";
import TestAnalytics from "./ModalContent/TestAnalytics";

const TestTemporaryDash = () => {
  const [showBrandFilter, setBrandFilterPopup] = React.useState(false);
  const userRole = checkUserRole();
  const dispatchAction = useDispatch();

  const campaignDetails = useSelector((state) => state.userSelections);
  const checkEditAccess = pageAccessAllowed("campaigns", "can_edit");
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchCampaign(id, navigate);
  }, []);

  return (
    <StyledMainBox component="main">
      {typeof campaignDetails.campaign_detail !== "undefined" && typeof campaignDetails.brand_filters !== "undefined" && (
        <>
          {checkEditAccess.success === true && campaignDetails.campaign_detail.campaign_status_code === 4 && (
            <StyledRightAlignedStack direction={"row"} spacing={2}>
              <LightGreyButton
                onClick={(e) => {
                  e.preventDefault();
                  dispatchAction(
                    updateStateData({
                      brand_report_view: true,
                    })
                  );
                  navigate("/check-campaigns/brand-report-view/" + id);
                }}
                label={"Brand View"}
                icon={<></>}
                selected={false}
              />
              <ActionButton
                onClick={(e) => {
                  e.preventDefault();
                  setBrandFilterPopup(!showBrandFilter);
                }}
                label={"Brand Report"}
                icon={<></>}
              />
            </StyledRightAlignedStack>
          )}

          <TestAnalytics viewMode={userRole.user_role} />

          {showBrandFilter && (
            <BrandReportFilter
              open={showBrandFilter}
              onClose={() => {
                setBrandFilterPopup(!showBrandFilter);
              }}
              size={"md"}
              closeModal={() => {
                setBrandFilterPopup(!showBrandFilter);
              }}
              usedFor={"update"}
              campaignRecord={typeof campaignDetails.campaign_detail !== "undefined" ? campaignDetails.campaign_detail : {}}
            />
          )}
        </>
      )}
    </StyledMainBox>
  );
};

export default AccessControl(TestTemporaryDash, "campaigns");
