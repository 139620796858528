import * as React from "react";
import {
  BorderedButtonStyle,
  DarkGreyActionButton,
  DarkRedActionButton,
  LightGreyActionButton,
  LightRedActionButton,
  DarkLogInButton,
  TextButtonStyle
} from "./Styles";

export default function ({ label, onClick, icon, ariaText }) {
  return (
    <DarkRedActionButton
      aria-label={typeof ariaText !== "undefined" ? ariaText : label}
      component={"button"}
      variant="contained"
      onClick={onClick}
      startIcon={icon}
      sx={{ mt: 3, mb: 2 }}
    >
      {label}
    </DarkRedActionButton>
  );
}

export const LightRedButton = ({ label, onClick, icon, ariaText }) => {
  return (
    <LightRedActionButton
      aria-label={typeof ariaText !== "undefined" ? ariaText : label}
      component={"button"}
      variant="contained"
      onClick={onClick}
      startIcon={icon}
      sx={{ mt: 3, mb: 2 }}
    >
      {label}
    </LightRedActionButton>
  );
};

export const DarkGreyButton = ({ label, onClick, icon, ariaText }) => {
  return (
    <DarkGreyActionButton
      aria-label={typeof ariaText !== "undefined" ? ariaText : label}
      component={"button"}
      variant="contained"
      onClick={onClick}
      startIcon={icon}
      sx={{ mt: 3, mb: 2 }}
    >
      {label}
    </DarkGreyActionButton>
  );
};

export const TextButton = ({ label, onClick, icon }) => {
  return (
    <TextButtonStyle component={"button"} variant="contained" onClick={onClick} startIcon={icon} sx={{ mt: 3, mb: 2 }}>
      {label}
    </TextButtonStyle>
  );
};

export const LogInButton = ({ label, onSubmit, icon, ariaText }) => {
  return (
    // <input type="submit" value={label}  onSubmit={onSubmit} startIcon={icon} sx={{ mt: 3, mb: 2 }}/>
    <DarkLogInButton aria-label={typeof ariaText !== "undefined" ? ariaText : label} type="submit" startIcon={icon} onSubmit={onSubmit} sx={{ mt: 3, mb: 2 }}>
      {label}
    </DarkLogInButton>
  );
};

export const LightGreyButton = ({ label, onClick, icon, ariaText }) => {
  return (
    <LightGreyActionButton
      component={"button"}
      aria-label={typeof ariaText !== "undefined" ? ariaText : label}
      variant="contained"
      onClick={onClick}
      startIcon={icon}
      sx={{ mt: 3, mb: 2 }}
    >
      {label}
    </LightGreyActionButton>
  );
};

export const ConditionalOutlinedButton = ({ label, onClick, selected, ariaText }) => {
  return (
    <BorderedButtonStyle
      component={"button"}
      aria-label={typeof ariaText !== "undefined" ? ariaText : label}
      variant={selected ? "contained" : "outlined"}
      onClick={onClick}
      sx={selected ? { mt: 3, mb: 2, color: "#fff", backgroundColor: "#AF3241" } : { mt: 3, mb: 2 }}
    >
      {label}
    </BorderedButtonStyle>
  );
};
